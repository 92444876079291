import { GetNextPageParamFunction, useInfiniteQuery, useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useEffect, useRef, useState } from 'react'
import { falconApiConfig } from '../../falcon-api-config'
import {
    AssistantConversationCreateDTO,
    AssistantConversationPatchDTO,
    ChatModel,
    ExplorerAssistantConversationService,
    OpenAPI,
    PaginatedDTO_AssistantConversationDTO_
} from '../client'
import { useRefreshCohortSize } from './useExplorerCohorts'

export const QUERY_KEYS = {
    conversations: 'conversations',
    conversation: 'conversation',
    cohort: 'cohort',
    cohortSize: 'cohortSize'
} as const

export function useConversationsInfinite(referenceCohortId?: string | null, limit = 100) {
    const getNextPageParam: GetNextPageParamFunction<PaginatedDTO_AssistantConversationDTO_> = (lastPage) => {
        return lastPage.meta.currentPage < lastPage.meta.totalPages ? lastPage.meta.currentPage + 1 : undefined
    }

    return useInfiniteQuery<PaginatedDTO_AssistantConversationDTO_>({
        queryKey: [QUERY_KEYS.conversations, referenceCohortId, limit],
        queryFn: ({ pageParam = 1 }) =>
            ExplorerAssistantConversationService.indexExplorerAssistantConversationGet({
                referenceCohortId: referenceCohortId,
                page: pageParam,
                limit
            }),
        getNextPageParam
    })
}

export function useConversation(id: string, debugMessages = false, model: ChatModel = 'gpt-4o') {
    const queryClient = useQueryClient()

    const [messages, setMessages] = useState<any[]>([])
    const [assistantIsResponding, setAssistantIsResponding] = useState<boolean>(false)
    const websocketUrl = `${falconApiConfig.falconApiUrl?.replace(new RegExp(/^http/), 'ws')}/explorer/assistant-conversation/${id}`
    const wsRef = useRef<WebSocket | null>(null)
    const [isRefreshing, setIsRefreshing] = useState<boolean>(false)

    useEffect(() => {
        setIsRefreshing(true)
    }, [])

    const { data: assistantConversationDetail, ...queryResult } = useQuery({
        queryKey: [QUERY_KEYS.conversation, id, debugMessages, model],
        queryFn: () =>
            ExplorerAssistantConversationService.getExplorerAssistantConversationIdGet({
                id,
                model
            }),
        onSuccess: (data) => {
            setMessages(data.messages || [])
        }
    })

    useRefreshCohortSize(assistantConversationDetail?.assistantCohortId || '', setIsRefreshing, isRefreshing)

    useEffect(() => {
        const ws = new WebSocket(websocketUrl)
        wsRef.current = ws

        ws.onopen = () => {
            ws.send(JSON.stringify({ token: String(OpenAPI.TOKEN) }))
        }

        ws.onmessage = (event) => {
            const newMessage = JSON.parse(event.data)
            if (newMessage?.type === 'tool' && newMessage?.status === 'success' && newMessage?.name === 'update_cohort') {
                setIsRefreshing(true)
            }
            if (newMessage?.type === 'ai') {
                setAssistantIsResponding(false)
            }
            setMessages((prevMessages) => [...prevMessages, newMessage])
        }

        ws.onerror = (error) => {
            console.error('WebSocket error:', error)
        }

        ws.onclose = () => {
            console.log('WebSocket connection closed')
        }

        return () => {
            ws.close()
        }
    }, [assistantConversationDetail?.assistantCohortId, queryClient, websocketUrl, setIsRefreshing])

    const sendMessage = (content: string) => {
        if (wsRef.current && wsRef.current.readyState === WebSocket.OPEN) {
            wsRef.current.send(JSON.stringify({ content }))
            setMessages((prevMessages) => [...prevMessages, { type: 'human', content }])
            setAssistantIsResponding(true)
        }
    }

    return {
        ...queryResult,
        messages,
        sendMessage,
        title: assistantConversationDetail?.title,
        assistantCohortId: assistantConversationDetail?.assistantCohortId,
        referenceCohortId: assistantConversationDetail?.referenceCohortId,
        assistantIsResponding,
        refreshCohortSize: () => {
            setIsRefreshing(true)
        },
        isRefreshing
    }
}

export function useCreateConversation() {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: (data: AssistantConversationCreateDTO) =>
            ExplorerAssistantConversationService.createExplorerAssistantConversationPost({
                requestBody: data
            }),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.conversations] })
        }
    })
}

export function useUpdateConversation() {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: ({ id, data }: { id: string; data: AssistantConversationPatchDTO }) =>
            ExplorerAssistantConversationService.patchExplorerAssistantConversationIdPatch({
                id,
                requestBody: data
            }),
        onSuccess: (_, variables) => {
            queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.conversations] })
            queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.conversation, variables.id] })
        }
    })
}

export function useDeleteConversation() {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: (id: string) => ExplorerAssistantConversationService.deleteExplorerAssistantConversationIdDelete({ id }),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.conversations] })
        }
    })
}
