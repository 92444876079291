/* eslint-disable string-to-lingui/missing-lingui-transformation */
import { DateAwareFilterDTO_Output, ExceptDTO_Output, QueryDTO_Output } from '@om1/falcon-api'
import CryptoJS from 'crypto-js'
import { Dispatch, FC, SetStateAction } from 'react'
import { FilterTypes, QueryBlock } from './QueryBlock'

const generateDeterministicId = (node: FilterTypes, index: number): number => {
    const { blockId, ...nodeWithoutBlockId } = node
    const hash = CryptoJS.SHA256(JSON.stringify(nodeWithoutBlockId)).toString() // Create a SHA-256 hash
    return (parseInt(hash.slice(0, 15), 16) % Number.MAX_SAFE_INTEGER) + index // Convert hash to a number
}
const stampNodeBlockIds = (node: FilterTypes, indexProp: number): FilterTypes => {
    try {
        if (!node) return node

        const newBlockId = generateDeterministicId(node, indexProp)
        node.blockId = newBlockId

        switch (node.type) {
            case 'RelativeDateFilterDTO':
                node.subjectFilter = stampNodeBlockIds(node.subjectFilter, indexProp + 1) as DateAwareFilterDTO_Output
                node.referenceFilter = stampNodeBlockIds(node.referenceFilter, indexProp + 2) as DateAwareFilterDTO_Output
                break
            case 'AndDTO':
                if (node.and) {
                    node.and = node.and.map((node, index) => stampNodeBlockIds(node, index))
                }
                break
            case 'OrDTO':
                if (node.or) {
                    node.or = node.or.map((node, index) => stampNodeBlockIds(node, index))
                }
                break
            case 'ExceptDTO':
                if (node.except) {
                    node.except = node.except.map((node, index) => stampNodeBlockIds(node, index))
                }
                break
        }
        return node
    } catch (error) {
        console.error('Error stamping node block IDs:', error)
        return node
    }
}

export const stampQueryBlockIds = (query: QueryDTO_Output | undefined): QueryDTO_Output | undefined => {
    try {
        if (!query || !query.filters) return query

        query.filters = query.filters.map((node, index) => stampNodeBlockIds(node, index))
        return query
    } catch (error) {
        console.error('Error stamping query block IDs:', error)
        return query
    }
}

const isBaseDTOFilter = (node: QueryDTO_Output | null | undefined): boolean => {
    return Boolean(
        node &&
            node.filters?.length === 1 &&
            node.filters[0].type === 'ExceptDTO' &&
            (node.filters[0].except.length === 1 || node.filters[0].except.length === 2) &&
            node.filters[0].except.every((exceptNode) => exceptNode.type === 'AndDTO')
    )
}

const isExceptDTO = (node: any): node is ExceptDTO_Output => {
    return node.type === 'ExceptDTO'
}

export const Query: FC<{
    query?: QueryDTO_Output | null
    selectedBlockId?: number | null
    setSelectedBlockId?: Dispatch<SetStateAction<number | undefined>>
    isBase?: boolean
}> = ({ query, selectedBlockId, setSelectedBlockId, isBase }) => {
    const isBaseDTO = isBase && isBaseDTOFilter(query)
    return (
        <div style={{ minHeight: '66.54dvh', marginBottom: '25px' }}>
            {isBaseDTO && query?.filters && isExceptDTO(query.filters[0]) && (
                <>
                    <h4>Inclusion Criteria: </h4>
                    <QueryBlock
                        key={`query-block-inclusion`}
                        block={{ node: query.filters[0].except[0] }}
                        selectedBlockId={selectedBlockId}
                        onClick={(blockId: number) => {
                            setSelectedBlockId && setSelectedBlockId(blockId)
                        }}
                    />
                    {query.filters[0].except.length > 1 && (
                        <>
                            <h4>Exclusion Criteria: </h4>
                            <QueryBlock
                                key={`query-block-exclusion`}
                                block={{ node: query.filters[0].except[1] }}
                                selectedBlockId={selectedBlockId}
                                onClick={(blockId: number) => {
                                    setSelectedBlockId && setSelectedBlockId(blockId)
                                }}
                            />
                        </>
                    )}
                </>
            )}
            {!isBaseDTO &&
                query?.filters?.map((filter, index) => {
                    return (
                        <QueryBlock
                            key={`query-block-${index}`}
                            block={{ node: filter }}
                            selectedBlockId={selectedBlockId}
                            onClick={(blockId: number) => {
                                setSelectedBlockId && setSelectedBlockId(blockId)
                            }}
                        />
                    )
                })}
        </div>
    )
}
