/* eslint-disable string-to-lingui/missing-lingui-transformation */
import { Message } from '@chatscope/chat-ui-kit-react'
import { t } from '@lingui/macro'
import AddCommentIcon from '@mui/icons-material/AddComment'
import CommentIcon from '@mui/icons-material/Comment'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import SaveAsIcon from '@mui/icons-material/SaveAs'
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material'
import Divider from '@mui/material/Divider'
import Menu, { MenuProps } from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Tooltip from '@mui/material/Tooltip'
import { alpha, styled } from '@mui/material/styles'
import { AssistantConversationPatchDTO, CohortCreateDTO, QueryDTO_Input } from '@om1/falcon-api'
import { AssistantConversationCreateDTO } from '@om1/falcon-api/codegen/client/models/AssistantConversationCreateDTO'
import {
    useConversationsInfinite,
    useCreateConversation,
    useDeleteConversation,
    useUpdateConversation
} from '@om1/falcon-api/codegen/hooks/useExplorerAssistantConversation'
import { useCohort, useCreateCohort } from '@om1/falcon-api/codegen/hooks/useExplorerCohorts'
import { useConversationFromParams } from '@om1/falcon-api/codegen/hooks/useParams'
import EditableParagraph from '@om1/platform-components/EditableParagraph'
import { ArrowLeft, Bot, Send, User, Wrench } from 'lucide-react'
import React, { memo, ReactElement, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import ReactJson from 'react-json-view'
import { withRouter } from 'react-router-dom'
import { Split } from '../components/Split'
import { Query } from '../components/query/Query'
import { FilterTypes, QueryBlock } from '../components/query/QueryBlock'
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'
import { lucario as theme } from 'react-syntax-highlighter/dist/esm/styles/prism'
import ReactMarkdown from 'react-markdown'

const RawToggleButton: React.FC<{ showRaw: boolean; setShowRaw: (showRaw: boolean) => void }> = ({ showRaw, setShowRaw }) => {
    return (
        <Button onClick={() => setShowRaw(!showRaw)} style={{ marginBottom: '10px' }}>
            {showRaw ? 'Show Query Block' : 'Show Raw JSON'}
        </Button>
    )
}

const FormattedMessage: React.FC<{ message: any }> = ({ message }) => {
    const [showRaw, setShowRaw] = useState(false)
    return (
        <ReactMarkdown
            components={{
                code: ({ children, className, node, ...props }) => {
                    const language = className?.replace('language-', '')
                    if (language === 'json') {
                        if (showRaw) {
                            return (
                                <>
                                    <RawToggleButton showRaw={showRaw} setShowRaw={setShowRaw} />
                                    <ReactJson src={JSON.parse(children as string)} />
                                </>
                            )
                        }
                        const messageParts = message.content.split('```')
                        const codeBlock = JSON.parse(messageParts[1].trim().replace(/^json/, ''))
                        return (
                            <>
                                <RawToggleButton showRaw={showRaw} setShowRaw={setShowRaw} />
                                <QueryBlock block={{ node: codeBlock as FilterTypes }} />
                            </>
                        )
                    }
                    return (
                        <SyntaxHighlighter language={language} style={theme}>
                            {Array.isArray(children) ? children : [children]}
                        </SyntaxHighlighter>
                    )
                },
                ul: ({ children, className, node, ...props }) => {
                    return (
                        <ul style={{ margin: '-10px 5px -10px 5px', padding: '5px', paddingLeft: '15px' }} {...props}>
                            {children}
                        </ul>
                    )
                },
                li: ({ children, className, node, ...props }) => {
                    return (
                        <li style={{ margin: '-5px', padding: '0px' }} {...props}>
                            {children}
                        </li>
                    )
                },
                h3: ({ children, ...props }) => {
                    return (
                        <h3 style={{ margin: '0px' }} {...props}>
                            {children}
                        </h3>
                    )
                }
            }}
        >
            {message.content}
        </ReactMarkdown>
    )
}

export const renderMessage = (message: any): ReactElement => {
    const type =
        message['type'] === 'ai'
            ? message['additional_kwargs']?.tool_calls && message['additional_kwargs'].tool_calls.length > 0
                ? 'tool'
                : 'ai'
            : message['type']
    switch (type) {
        case 'tool':
            if (message?.additional_kwargs?.tool_calls && message.additional_kwargs.tool_calls.length > 0) {
                const tool_calls = message?.additional_kwargs?.tool_calls
                return (
                    <Tooltip
                        title={
                            <div>
                                {tool_calls.map((tool, i: number) => {
                                    return (
                                        <div key={i}>
                                            <strong>Type:</strong> {tool.type}
                                            <br />
                                            <strong>Args:</strong>{' '}
                                            {tool_calls[0].function.name !== 'get_my_cohort' && tool_calls[0].function.name !== 'summarize_my_cohort'
                                                ? tool.function.arguments
                                                : JSON.stringify({
                                                      cohort_id: JSON.parse(tool.function.arguments).cohort_id,
                                                      user_token: 'REDACTED'
                                                  })}
                                        </div>
                                    )
                                })}
                            </div>
                        }
                    >
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '8px',
                                fontSize: '0.85em',
                                color: '#666',
                                fontStyle: 'italic'
                            }}
                        >
                            <span>{tool_calls[0].function.name} called</span>
                        </div>
                    </Tooltip>
                )
            } else {
                return (
                    <Tooltip
                        title={
                            <div>
                                <strong>Tool:</strong> {`${message.name}`}
                                <br />
                                <strong>Response:</strong> {`${message.status}`}
                            </div>
                        }
                    >
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '8px',
                                fontSize: '0.85em',
                                color: '#666',
                                fontStyle: 'italic'
                            }}
                        >
                            <span>{message.name} response received</span>
                        </div>
                    </Tooltip>
                )
            }
        case 'human':
            return (
                <Message
                    key={`human-message`}
                    model={{
                        sentTime: 'just now',
                        direction: 'outgoing',
                        position: 'normal',
                        type: 'html'
                    }}
                    style={{
                        color: '#012D72',
                        padding: '2px 4px',
                        margin: '1px 0',
                        lineHeight: '1.2'
                    }}
                >
                    <Message.CustomContent>
                        <FormattedMessage message={message} />
                    </Message.CustomContent>
                </Message>
            )
        case 'ai':
            return (
                <Message
                    key={`ai-message`}
                    model={{
                        sentTime: 'just now',
                        direction: 'incoming',
                        position: 'normal',
                        type: 'html'
                    }}
                    style={{
                        color: '#000000',
                        padding: '2px 4px',
                        margin: '1px 0',
                        lineHeight: '1.2'
                    }}
                >
                    <Message.CustomContent>
                        <FormattedMessage message={message} />
                    </Message.CustomContent>
                </Message>
            )
        default:
            return <>{JSON.stringify(message)}</>
    }
}

const StyledMenu = styled((props: MenuProps) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
        }}
        {...props}
    >
        {props.children}
    </Menu>
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 180,
        color: 'rgb(55, 65, 81)',
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '4px 0'
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5)
            },
            '&:active': {
                backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity)
            }
        },
        ...theme.applyStyles('dark', {
            color: theme.palette.grey[300]
        })
    }
}))

const MessageInput = React.memo((props: { message: string; setMessage: (message: string) => void; isInvoking: boolean }) => {
    const handleChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            props.setMessage(e.target.value)
        },
        [props]
    )

    return (
        <input
            type='text'
            value={props.message}
            onChange={handleChange}
            placeholder='Type your message...'
            style={{
                flex: 1,
                padding: '0.5rem',
                border: '1px solid #e2e8f0',
                borderRadius: '0.5rem',
                outline: 'none',
                transition: 'box-shadow 0.2s',
                boxShadow: props.isInvoking ? 'none' : '0 0 0 2px #3182ce',
                fontFamily: 'Metropolis, sans-serif',
                color: '#012D72',
                fontSize: '1rem',
                lineHeight: '1.2'
            }}
            disabled={props.isInvoking}
        />
    )
})

MessageInput.displayName = 'MessageInput'

// Define the form as a separate component
interface MessageFormProps {
    sendMessage: (content: string) => void
}

const MessageForm = memo(({ sendMessage }: MessageFormProps) => {
    const [message, setMessage] = useState('')
    const [isInvoking, setIsInvoking] = useState(false)

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault()
        if (!message.trim() || isInvoking) return

        setIsInvoking(true)
        try {
            sendMessage(message.trim()) // Use the message state
            setMessage('') // Clear the message input after sending
        } finally {
            setIsInvoking(false)
        }
    }

    return (
        <form onSubmit={handleSubmit} style={{ display: 'flex', gap: '1rem' }}>
            <MessageInput message={message} setMessage={setMessage} isInvoking={isInvoking} />
            <button
                type='submit'
                disabled={!message.trim() || isInvoking}
                style={{
                    padding: '0.5rem 1rem',
                    color: '#ffffff',
                    borderRadius: '0.5rem',
                    transition: 'background-color 0.2s',
                    display: 'flex',
                    alignItems: 'center',
                    gap: '0.5rem',
                    backgroundColor: isInvoking ? '#63b3ed' : '#3182ce'
                }}
            >
                <Send style={{ width: '1rem', height: '1rem' }} />
                <span>Send</span>
            </button>
        </form>
    )
})

MessageForm.displayName = 'MessageForm'

export function ChatView({ match, history }) {
    const navigate = history.push
    const [showQueryComponent, setShowQueryComponent] = useState(true)

    const conversationParams = useConversationFromParams(match)
    const { title, conversationId, isLoading, sendMessage, messages, assistantCohortId, referenceCohortId, assistantIsResponding, isRefreshing } =
        useMemo(() => {
            return conversationParams
        }, [conversationParams])

    const { data: conversations } = useConversationsInfinite(referenceCohortId)
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const { mutate: updateConversation } = useUpdateConversation()
    const [openDialog, setOpenDialog] = useState(false)
    const [newCohortName, setNewCohortName] = useState('') // State for cohort name
    const { data: cohort } = useCohort(assistantCohortId || '', isRefreshing)
    const { mutate: createCohort } = useCreateCohort()
    const deleteConversation = useDeleteConversation()

    const handleDelete = async (id: string, e: React.MouseEvent) => {
        e.stopPropagation()
        if (window.confirm('Are you sure you want to delete this conversation?')) {
            await deleteConversation.mutateAsync(id).then(() => {
                referenceCohortId ? navigate(`/cohort-builder/custom/${referenceCohortId}/edit`) : navigate(`/cohort-builder/dashboard/`)
            })
        }
    }

    const handleUpdateConversation = (newTitle: string) => {
        const updatedConversation: AssistantConversationPatchDTO = {
            title: newTitle
        }

        updateConversation({ id: conversationId, data: updatedConversation })
    }

    const messagesEndRef = useRef<HTMLDivElement | null>(null)
    const { mutate } = useCreateConversation()

    function onConversationSelect(id: string) {
        navigate(`/cohort-builder/assistant/conversation/${id}`)
    }

    const handleCreateConversation = () => {
        const newConversation: AssistantConversationCreateDTO = {
            referenceCohortId: referenceCohortId
        }

        mutate(newConversation, {
            onSuccess: (createdConversation) => {
                onConversationSelect?.(createdConversation.id)
            }
        })
    }

    useEffect(() => {
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView({ behavior: 'smooth' })
        }
    }, [messages])

    if (isLoading) {
        return (
            <div style={{ minHeight: '100vh', backgroundColor: '#F5F5F5', padding: '2rem', display: 'flex' }}>
                <div style={{ flex: 2, margin: '0 auto' }}>
                    <div style={{ animation: 'pulse 1.5s infinite', gap: '1rem', display: 'flex', flexDirection: 'column' }}>
                        <div style={{ height: '2rem', backgroundColor: '#e2e8f0', borderRadius: '0.5rem', width: '25%' }}></div>
                        <div style={{ display: 'flex', flexDirection: 'column', gap: '0.75rem', height: '100%' }}>
                            <div style={{ height: '6rem', backgroundColor: '#e2e8f0', borderRadius: '0.5rem' }}></div>
                            <div style={{ height: '6rem', backgroundColor: '#e2e8f0', borderRadius: '0.5rem' }}></div>
                            <div style={{ height: '6rem', backgroundColor: '#e2e8f0', borderRadius: '0.5rem' }}></div>
                            <div style={{ height: '8rem', backgroundColor: '#e2e8f0', borderRadius: '0.5rem', marginTop: '37.5%' }}></div>
                        </div>
                    </div>
                </div>

                <div style={{ flex: 1, marginLeft: '1rem', backgroundColor: '#e2e8f0', borderRadius: '0.5rem', padding: '1rem' }}>
                    <div style={{ animation: 'pulse 1.5s infinite', height: '100%', borderRadius: '0.5rem' }}></div>
                </div>
            </div>
        )
    }

    const toggleQueryView = () => {
        if (cohort?.query !== undefined) {
            setShowQueryComponent((prev) => !prev)
        }
    }

    const open = Boolean(anchorEl)
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
        setAnchorEl(null)
    }

    const handleSaveAs = (event: React.MouseEvent<HTMLElement>) => {
        handleClose()
        setOpenDialog(true)
    }

    const handleDialogClose = () => {
        setOpenDialog(false)
    }

    const handleDialogSave = () => {
        const newCohortData: CohortCreateDTO = {
            name: newCohortName,
            query: cohort?.query as QueryDTO_Input,
            analyticsDatasetId: String(cohort?.analyticsDataset.id),
            isPrivate: false
        }

        createCohort(newCohortData, {
            onSuccess: (data) => {
                setOpenDialog(false)
                navigate(`/cohort-builder/custom/${data.id}/edit`)
            },
            onError: (error) => {
                console.error('Error creating cohort:', error)
            }
        })
    }

    return (
        <div style={{ backgroundColor: '#F5F5F5', display: 'flex', flexDirection: 'column' }}>
            {/* Header */}
            <div style={{ backgroundColor: '#ffffff', borderBottom: '1px solid #e2e8f0' }}>
                <div
                    style={{
                        margin: '0 auto',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        maxHeight: '75px'
                    }}
                >
                    <div style={{ display: 'flex', alignItems: 'center', gap: '1rem', marginLeft: '12.5px' }}>
                        <button
                            onClick={() => history.goBack()}
                            style={{ padding: '0.5rem', borderRadius: '50%', transition: 'background-color 0.2s', cursor: 'pointer' }}
                        >
                            <ArrowLeft style={{ width: '1.25rem', height: '1.25rem' }} />
                        </button>
                        <div style={{ marginTop: '-15px' }}>
                            <EditableParagraph
                                tooltip={t`Click to name conversation`}
                                sx={{
                                    alignSelf: 'flex-start',
                                    marginTop: 0
                                }}
                                text={title || conversationId}
                                editable={true}
                                onSave={handleUpdateConversation}
                            />
                        </div>
                        {referenceCohortId && (
                            <p
                                style={{ fontSize: '1rem', color: '#718096', cursor: 'pointer', marginLeft: '35px', lineHeight: '0.2rem' }}
                                onClick={() => navigate(`/cohort-builder/custom/${referenceCohortId}/edit`)}
                            >
                                Cohort: {referenceCohortId}
                            </p>
                        )}
                    </div>
                    <div>
                        <Button id='button' aria-haspopup='true' onClick={handleClick}>
                            Options
                        </Button>
                        <StyledMenu anchorEl={anchorEl} open={open} onClose={handleClose}>
                            <MenuItem onClick={handleSaveAs} disableRipple>
                                <SaveAsIcon />
                                Save As New Custom Cohort
                            </MenuItem>
                            <MenuItem
                                onClick={() => {
                                    handleCreateConversation()
                                    handleClose()
                                }}
                                disableRipple
                                key={`menuItem-new`}
                            >
                                <AddCommentIcon />
                                Start New Conversation
                            </MenuItem>
                            <MenuItem
                                onClick={(e) => {
                                    handleDelete(conversationId, e)
                                }}
                                disableRipple
                            >
                                <DeleteForeverIcon />
                                Delete Conversation
                            </MenuItem>
                            <Divider sx={{ my: 0.5 }} />
                            {conversations?.pages.map((page, pageIndex) =>
                                page.data
                                    .filter((conversation) => conversation.id !== conversationId)
                                    .map((conversation, index) => (
                                        <MenuItem
                                            onClick={() => {
                                                navigate(`/cohort-builder/assistant/conversation/${conversation.id}`)
                                                handleClose()
                                            }}
                                            disableRipple
                                            key={`menuItem-${index}`}
                                        >
                                            <CommentIcon />
                                            {conversation.title || conversation.id}
                                        </MenuItem>
                                    ))
                            )}
                        </StyledMenu>
                    </div>
                </div>
            </div>
            <Split sizes={[67, 33]} minSize={[400, 400]}>
                <div style={{ minHeight: '80dvh', height: '80dvh', backgroundColor: '#F5F5F5', display: 'flex', flexDirection: 'column' }}>
                    {/* Messages */}
                    <div style={{ flex: 1, overflowY: 'auto', padding: '1rem', scrollbarWidth: 'none', msOverflowStyle: 'none' }}>
                        <style>
                            {`
                                /* Hide scrollbar for Chrome, Safari and Opera */
                                .hide-scrollbar::-webkit-scrollbar {
                                    display: none;
                                }

                                /* Hide scrollbar for IE, Edge and Firefox */
                                .hide-scrollbar {
                                    -ms-overflow-style: none;  /* IE and Edge */
                                    scrollbar-width: none;  /* Firefox */
                                }
                            `}
                        </style>
                        <div className='hide-scrollbar' style={{ margin: '0 auto', display: 'flex', flexDirection: 'column', gap: '1.5rem' }}>
                            {messages.map((msg, index) => {
                                const type =
                                    msg['type'] === 'ai'
                                        ? msg['additional_kwargs']?.tool_calls && msg['additional_kwargs'].tool_calls.length > 0
                                            ? 'tool'
                                            : 'ai'
                                        : msg['type']
                                return (
                                    <div
                                        key={index}
                                        style={{
                                            display: 'flex',
                                            flexDirection: type !== 'human' ? 'row' : 'row-reverse',
                                            gap: '1rem'
                                        }}
                                    >
                                        <div
                                            style={{
                                                flexShrink: 0,
                                                width: '2rem',
                                                height: '2rem',
                                                borderRadius: '50%',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                backgroundColor: type === 'human' ? '#ebf8ff' : '#F5F5F5',
                                                marginTop: '25.5px'
                                            }}
                                        >
                                            {type === 'human' ? (
                                                <User
                                                    style={{
                                                        width: '1.75rem',
                                                        height: '1.75rem',
                                                        color: '#3182CE',
                                                        fontFamily: ['Metropolis', 'sans-serif'].join(','),
                                                        backgroundColor: 'white',
                                                        borderRadius: '100px'
                                                    }}
                                                />
                                            ) : type === 'tool' ? (
                                                <Wrench
                                                    style={{
                                                        width: '1.75rem',
                                                        height: '1.75rem',
                                                        color: '#718096',
                                                        fontFamily: ['Metropolis', 'sans-serif'].join(','),
                                                        backgroundColor: 'transparent',
                                                        borderRadius: '100px'
                                                    }}
                                                />
                                            ) : (
                                                <Bot
                                                    style={{
                                                        width: '1.75rem',
                                                        height: '1.75rem',
                                                        color: '#718096',
                                                        fontFamily: ['Metropolis', 'sans-serif'].join(','),
                                                        backgroundColor: '#FFFFFF',
                                                        borderRadius: '100px'
                                                    }}
                                                />
                                            )}
                                        </div>
                                        {type === 'human' && (
                                            <div
                                                style={{
                                                    flexGrow: 1,
                                                    padding: '1rem',
                                                    borderRadius: '0.5rem',
                                                    textAlign: 'right',
                                                    backgroundColor: '#FFFFFF',
                                                    border: '1px solid #3182CE'
                                                }}
                                            >
                                                <p style={{ color: '#2d3748', whiteSpace: 'pre-wrap' }}>{renderMessage(msg)}</p>
                                            </div>
                                        )}
                                        {type === 'ai' && (
                                            <div
                                                style={{
                                                    flexGrow: 1,
                                                    padding: '1rem',
                                                    borderRadius: '0.5rem',
                                                    textAlign: 'left',
                                                    backgroundColor: '#FFFFFF',
                                                    border: '1px solid #718096'
                                                }}
                                            >
                                                <p style={{ color: '#2d3748', whiteSpace: 'pre-wrap' }}>{renderMessage(msg)}</p>
                                            </div>
                                        )}
                                        {type === 'tool' && (
                                            <div
                                                style={{
                                                    flexGrow: 1,
                                                    padding: '1rem',
                                                    borderRadius: '0.5rem',
                                                    textAlign: 'left',
                                                    backgroundColor: '#transparent'
                                                }}
                                            >
                                                <p style={{ color: '#2d3748', whiteSpace: 'pre-wrap' }}>{renderMessage(msg)}</p>
                                            </div>
                                        )}
                                    </div>
                                )
                            })}
                            <div ref={messagesEndRef} />
                            {assistantIsResponding && (
                                <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem', color: '#718096', fontStyle: 'italic' }}>
                                    <CircularProgress color='inherit' size='1em' />
                                    <span>Assistant is responding...</span>
                                </div>
                            )}
                        </div>
                    </div>

                    {/* Input */}
                    <div style={{ backgroundColor: '#ffffff', borderTop: '1px solid #e2e8f0', padding: '1rem' }}>
                        <div style={{ margin: '0 auto' }}>
                            {/* {selectedBlockId && (
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: '0.5rem',
                                        padding: '0.5rem',
                                        backgroundColor: '#f0f4f8',
                                        borderRadius: '0.5rem',
                                        marginBottom: '1rem'
                                    }}
                                >
                                    <span style={{ fontSize: '1rem', color: '#2d3748' }}>Context: {selectedBlockId}</span>
                                    <button
                                        onClick={() => setSelectedBlockId(undefined)}
                                        style={{
                                            backgroundColor: 'transparent',
                                            border: 'none',
                                            color: '#e53e3e',
                                            cursor: 'pointer',
                                            fontSize: '1rem',
                                            lineHeight: '1',
                                            padding: '0',
                                            display: 'flex',
                                            alignItems: 'center'
                                        }}
                                        aria-label='Clear context'
                                    >
                                        &times;
                                    </button>
                                </div>
                            )} */}
                            <MessageForm sendMessage={sendMessage} />
                        </div>
                    </div>
                </div>
                <div
                    style={{
                        padding: '1rem 1rem 1rem 1rem',
                        height: '100%',
                        overflow: 'scroll'
                    }}
                >
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                        <div style={{ minWidth: '50px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <div style={{ marginRight: '10px' }}>Count:</div>
                            {isRefreshing ? <CircularProgress color='inherit' size='1em' /> : <div>{cohort?.cohortSize || '-'}</div>}
                        </div>

                        <div style={{ display: 'flex', gap: '1rem' }}>
                            {/* <button
                                onClick={refreshCohortSize}
                                style={{
                                    padding: '0.5rem 1rem',
                                    backgroundColor: '#3182ce',
                                    color: '#ffffff',
                                    border: 'none',
                                    borderRadius: '0.5rem',
                                    cursor: 'pointer',
                                    fontSize: '1rem',
                                    transition: 'background-color 0.3s, transform 0.2s',
                                    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                                    marginBottom: '10px',
                                    opacity: '10%'
                                }}
                                onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = '#2b6cb0')}
                                onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = '#3182ce')}
                                onMouseDown={(e) => (e.currentTarget.style.transform = 'scale(0.95)')}
                                onMouseUp={(e) => (e.currentTarget.style.transform = 'scale(1)')}
                            >
                                {'Refresh Cohort Size'}
                            </button> */}
                            <button
                                onClick={toggleQueryView}
                                style={{
                                    padding: '0.5rem 1rem',
                                    backgroundColor: cohort?.query === undefined ? 'gray' : '#3182ce',
                                    color: '#ffffff',
                                    border: 'none',
                                    borderRadius: '0.5rem',
                                    cursor: 'pointer',
                                    fontSize: '1rem',
                                    transition: 'background-color 0.3s, transform 0.2s',
                                    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                                    marginBottom: '10px'
                                }}
                                onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = cohort?.query === undefined ? 'gray' : '#2b6cb0')}
                                onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = cohort?.query === undefined ? 'gray' : '#3182ce')}
                                onMouseDown={(e) => (e.currentTarget.style.transform = 'scale(0.95)')}
                                onMouseUp={(e) => (e.currentTarget.style.transform = 'scale(1)')}
                                disabled={cohort?.query === undefined}
                            >
                                {showQueryComponent ? 'Show Raw JSON' : 'Show QueryBlock'}
                            </button>
                        </div>
                    </div>
                    {showQueryComponent
                        ? cohort?.query && (
                              <Query isBase query={cohort?.query} /*selectedBlockId={selectedBlockId} setSelectedBlockId={setSelectedBlockId}*/ />
                          )
                        : cohort?.query && <ReactJson src={JSON.parse(JSON.stringify(cohort?.query))} style={{ marginBottom: '25px' }} />}
                </div>
            </Split>

            {/* Save As Dialog */}
            <Dialog open={openDialog} onClose={handleDialogClose}>
                <DialogTitle>Save Cohort</DialogTitle>
                <DialogContent style={{ width: '400px', height: '100px' }}>
                    <TextField
                        autoFocus
                        margin='dense'
                        label='Cohort Name'
                        type='text'
                        fullWidth
                        value={newCohortName}
                        onChange={(e) => setNewCohortName(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose} color='primary'>
                        Cancel
                    </Button>
                    <Button onClick={handleDialogSave} color='primary' disabled={!newCohortName.trim()}>
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default withRouter(ChatView)
