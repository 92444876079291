/* eslint-disable string-to-lingui/missing-lingui-transformation */
import { FilterDTO_Output, OrDTO_Output } from '@om1/falcon-api'
import { FC } from 'react'

export const isPatientOrGroup = (node: OrDTO_Output) => {
    return (
        node.or.length === 3 &&
        node.or[0].type === 'FilterDTO' &&
        node.or[1].type === 'FilterDTO' &&
        node.or[2].type === 'FilterDTO' &&
        node.or[0].table === 'patient' &&
        node.or[1].table === 'patient' &&
        node.or[2].table === 'patient'
    )
}

export const PatientOrGroup: FC<{
    node: OrDTO_Output
    depth: number
    onClick?: (blockId: number, qualifierIndex?: number) => void
    selectedBlockId?: number
}> = ({ node }) => {
    const fieldValuesMap: Record<string, any> = (node.or as FilterDTO_Output[]).reduce((acc: Record<string, any>, filter) => {
        acc[filter.field] = filter.values
        return acc
    }, {})
    const { region, sub_region, state } = fieldValuesMap

    // Combine non-empty arrays and join them with commas
    const regionsArray = [region, sub_region, state].filter((arr) => Array.isArray(arr) && arr.length > 0).flat()

    const regionsText = regionsArray.length > 0 ? regionsArray.join(', ') : 'Any'

    return (
        <div style={{ fontSize: '0.75rem' }}>
            <span style={{ fontWeight: '800' }}>Region:</span> {regionsText}
        </div>
    )
}
